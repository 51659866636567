<template>
  <div class="big-sign">
    <div class="box-inner">
      <slot></slot>
      <h2 v-if="title" class="title">{{ title }}</h2>
      <p v-if="text" class="text">{{ text }}</p>
      <router-link v-if="buttonLink" :to="buttonLink" class="button">{{ buttonTitle }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BigSign',
  props: ['title', 'text', 'buttonTitle', 'buttonLink']
}
</script>

<style lang="scss">
  @import './style.scss';
</style>
