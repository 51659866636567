<script>
import configurations from '@/services/configurations'
import languages from '@/services/languages'

export default {
  name: 'Configurations',
  data () {
    return {
      initializing: true
    }
  },
  async setup () {
    await configurations.initialize()
  },
  mounted () {
    return languages.initialize()
      .then(() => {
        this.initializing = false
      })
  }
}
</script>

<template>
  <router-view v-if="!initializing" />
</template>

<style lang="scss">
@import "style.scss";
</style>
