import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './main.scss'
import Translate from '@/components/translate/Translate'
import BigSign from '@/components/big-sign/BigSign'
import IconDownload from '@/components/icons/download'
import Loading from 'vue-loading-overlay'
import Toast from 'vue-toastification'
import configurationsService from '@/services/configurations'
import languagesService from '@/services/languages'
import permissionsService from '@/services/permissions'
import 'vue-toastification/dist/index.css'

const app = createApp(App)

app.mixin({
  methods: {
    translateTerm: languagesService.getTerm,
    hasAnyPermission: permissionsService.hasAnyPermission,
    getConfiguration: configurationsService.getConfiguration
  }
})
app.component('translate', Translate)
app.component('big-sign', BigSign)
app.component('loading', Loading)
app.component('icon-download', IconDownload)

app.use(Toast, {
  position: 'top-right',
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false
})
app.use(router)

app.mount('#app')
