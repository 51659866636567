import axios from 'axios'

export default {
  initialize () {
    return axios
      .get('/config.json')
      .then((response) => {
        localStorage.setItem('config', JSON.stringify(response.data))
        if (localStorage.getItem('DICTIONARY_LANGUAGE') === null) {
          localStorage.setItem('DICTIONARY_LANGUAGE', response.data.DEFAULT_LANGUAGE)
        }
      })
      .catch((error) => {
        console.error('initialize configurations error', error)
      })
  },
  getConfiguration (key) {
    const config = localStorage.getItem('config')
    const configJson = JSON.parse(config)
    return configJson[key]
  }
}
