import axios from 'axios'
import configurationsService from '@/services/configurations'
import authenticationService from '@/services/authentication'
let lastUpdate
let permissions = []

function updateFromApi () {
  return axios({
    method: 'get',
    url: `${configurationsService.getConfiguration('API_URL')}/users/me/permissions`,
    headers: {
      Authorization: `Bearer ${authenticationService.getToken()}`
    }
  })
    .then((response) => {
      lastUpdate = new Date()
      permissions = response.data
      localStorage.setItem('permissions', JSON.stringify(permissions))
      localStorage.setItem('permissions_lastupdate', lastUpdate.getTime())
    })
    .catch((error) => {
      console.error('[permissions] updateFromApi error', error)
    })
}

function loadPermissionsFromLocalStorage () {
  const lsdata = localStorage.getItem('permissions')
  const lslastupdate = localStorage.getItem('permissions_lastupdate')
  if (lsdata && lsdata.length > 0) {
    permissions = JSON.parse(lsdata)
    if (lslastupdate) {
      lastUpdate = new Date(parseInt(lslastupdate))
    }
  }
  if (!lastUpdate || !permissions || permissions.length === 0) {
    return updateFromApi()
  } else {
    const currentDateTime = new Date().getTime()
    const lastUpdateTime = lastUpdate.getTime()
    if (currentDateTime - lastUpdateTime > (5 * 60 * 1000)) { // 5 minutes
      return updateFromApi()
    }
  }
  return Promise.resolve()
}

export default {
  initialize () {
    return updateFromApi()
  },
  hasAnyPermission (permissionsToValidate) {
    if (!permissions || permissions.length === 0) {
      loadPermissionsFromLocalStorage()
    }

    const hasOne = permissionsToValidate.find((permissionCode) => {
      return permissions.includes(permissionCode)
    })
    if (!hasOne) {
      return false
    }
    return true
  }
}
