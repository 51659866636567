import decode from 'jwt-decode'

export const getToken = () => {
  return localStorage.getItem('token')
}

export const isTokenExpired = () => {
  try {
    const decoded = decode(getToken())
    if (decoded.exp < Date.now() / 1000) {
      return true
    }
    return false
  } catch (err) {
    return true
  }
}

export const saveToken = token => {
  localStorage.setItem('token', token)
  updateLanguageFromLocalStorage()
}

export const destroyToken = () => {
  localStorage.removeItem('token')
}

export const getDecodedToken = () => {
  try {
    const decoded = decode(getToken())
    return decoded
  } catch (err) {
    return null
  }
}

export const updateLanguageFromLocalStorage = () => {
  const decoded = getDecodedToken()
  if (decoded) {
    localStorage.setItem('DICTIONARY_LANGUAGE', decoded.language)
  }
}

export default {
  getToken,
  saveToken,
  destroyToken,
  getDecodedToken,
  updateLanguageFromLocalStorage
}
