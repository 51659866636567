<template>
  <svg
    :fill="color"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.5 17C13.1949 17 17 13.1949 17 8.5C17 3.80508 13.1949 0 8.5 0C3.80508 0 0 3.80508 0 8.5C0 13.1949 3.80508 17 8.5 17ZM4.28984 8.82871C4.37285 8.62949 4.56543 8.5 4.78125 8.5H6.90625V5.3125C6.90625 4.7258 7.38205 4.25 7.96875 4.25H9.03125C9.61795 4.25 10.0938 4.7258 10.0938 5.3125V8.5H12.2188C12.4335 8.5 12.6275 8.62919 12.7095 8.82788C12.792 9.02657 12.7463 9.25487 12.5943 9.40694L8.87556 13.1257C8.66811 13.3331 8.33169 13.3331 8.12451 13.1257L4.40576 9.40694C4.25332 9.25371 4.20684 9.02793 4.28984 8.82871Z" fill="#232323" fill-opacity="0.3"/>
  </svg>
</template>

<script>
export default {
  name: 'IconDownload',
  props: ['color']
}
</script>
