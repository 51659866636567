<template>
  {{ text }}
</template>

<script>
import languages from '@/services/languages'

export default {
  name: 'Translate',
  props: ['lang', 'code'],
  data () {
    return {
      text: languages.getTerm(this.lang, this.code)
    }
  }
}
</script>
