import axios from 'axios'
import configurationsService from '@/services/configurations'
const dictionaries = {}
const DB_NAME = 'farmacovigilancia'
const dbDate = new Date()
dbDate.setMinutes(0, 0, 0)
const DB_VERSION = parseInt(dbDate.getTime() / (1 * 60 * 60 * 1000)) // cada 1 hs
let OBJECTSTORE_TERMS_NAME = 'terms';
let CURRENT_LANGUAGE;
let DB

export default {
  getDb () {
    return new Promise((resolve, reject) => {
      CURRENT_LANGUAGE = localStorage.getItem('DICTIONARY_LANGUAGE') ? localStorage.getItem('DICTIONARY_LANGUAGE') : configurationsService.getConfiguration('DEFAULT_LANGUAGE');
      if (DB) {
          resolve(DB)
      }
      const request = window.indexedDB.open(DB_NAME, DB_VERSION)
      request.onerror = e => {
        console.error('indexedDB open error', e)
        reject(new Error('Error'))
      }
      request.onsuccess = e => {
        DB = e.target.result
        resolve(DB)
      }
      request.onupgradeneeded = e => {
        DB = e.target.result
        if (DB.objectStoreNames.contains(OBJECTSTORE_TERMS_NAME)) {
          DB.deleteObjectStore(OBJECTSTORE_TERMS_NAME)
        }
        const objectStoreTerms = DB.createObjectStore(OBJECTSTORE_TERMS_NAME)
        objectStoreTerms.createIndex('key', ['lang', 'key'], { unique: true })
      }
    })
  },
  saveTerms (terms) {
    return this.getDb()
      .then((db) => {
        return new Promise(resolve => {
          const transTerms = db.transaction([OBJECTSTORE_TERMS_NAME], 'readwrite')
          transTerms.oncomplete = () => {
            resolve(db)
          }
          const store = transTerms.objectStore(OBJECTSTORE_TERMS_NAME)
          dictionaries[CURRENT_LANGUAGE] = {}
          terms.forEach((elem) => {
            store.add(elem.value, [CURRENT_LANGUAGE, elem.key])
            dictionaries[CURRENT_LANGUAGE][elem.key] = elem.value
          })
        })
      })
  },
  loadFromApi () {
    return axios
      .get(`${configurationsService.getConfiguration('API_URL')}/config/languages/${CURRENT_LANGUAGE}`)
      .then((response) => {
        return this.saveTerms(response.data)
      })
      .catch((error) => {
        console.error('initialize dictionary error', error)
      })
  },
  tryToLoadFromDb () {
    return this.getDb()
      .then((db) => {
        return new Promise((resolve) => {
          let transaction
          let haveTermsInCurrentLang = false
          try {
            transaction = db.transaction([OBJECTSTORE_TERMS_NAME], 'readonly')
          } catch (error) {
            return resolve(false)
          }
          transaction.oncomplete = () => {
            resolve(haveTermsInCurrentLang)
          }
          transaction.onerror = () => {
            resolve(false)
          }
          const store = transaction.objectStore(OBJECTSTORE_TERMS_NAME)
          store.openCursor().onsuccess = e => {
            const cursor = e.target.result
            if (cursor) {
              if (cursor.key[0] === CURRENT_LANGUAGE) {
                haveTermsInCurrentLang = true
              }
              if (!dictionaries[cursor.key[0]]) {
                dictionaries[cursor.key[0]] = {};
              }
              dictionaries[cursor.key[0]][cursor.key[1]] = cursor.value
              cursor.continue()
            }
          }
        })
      })
  },
  initialize () {
    return this.tryToLoadFromDb()
      .then((loaded) => {
        if (!loaded) {
          return this.loadFromApi()
        }
      })
      .catch((error) => {
        console.error('[languages] initialize error', error)
      })
  },
  getTerm (lang, key) {
    if (!dictionaries[lang] || !dictionaries[lang][key]) {
      return key
    }
    return dictionaries[lang][key]
  }
}
