<script>
import Configurations from './Configurations.vue'

export default {
  components: {
    Configurations
  }
}
</script>

<template>
  <Suspense>
    <Configurations />
  </Suspense>
</template>

<style lang="scss">
@import "style.scss";
</style>
