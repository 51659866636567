import { createRouter, createWebHashHistory } from 'vue-router'
import { getToken, isTokenExpired, destroyToken } from '@/common/auth'
import permissionsService from '@/services/permissions'

function hasTokenValid (next) {
  if (!getToken() || isTokenExpired()) {
    destroyToken()
    next('/unauthorized')
  } else {
    next()
  }
}

function hasPermissionToEnter (permissionCodes, next) {
  if (!permissionsService.hasAnyPermission(permissionCodes)) {
    next('/notfound')
  } else {
    next()
  }
}

const routes = [
  {
    path: '',
    name: 'Sidebar',
    component: () => import('../components/sidebar/Sidebar.vue'),
    beforeEnter: (to, from, next) => {
      hasTokenValid(next)
    },
    children: [
      {
        path: '',
        alias: '/',
        name: 'Home',
        component: () => import('../views/home/home.vue')
      },
      {
        path: '/settings/profiles/:id',
        name: 'User permissions',
        component: () => import('../views/user-permissions/userPermissions.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['ORGANIZATION_SETTINGS_ROLES_VIEW_PERMISSIONS', 'ORGANIZATION_SETTINGS_ROLES_EDIT_PERMISSIONS'], next)
        }
      },
      {
        path: '/suspicions',
        alias: '/suspicions',
        name: 'Suspictions',
        component: () => import('../views/suspictions/Suspictions.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['SUSPICTIONS_VIEWLIST'], next)
        }
      },
      {
        path: '/products',
        name: 'Products',
        component: () => import('../views/products/Products.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['PRODUCTS_VIEW'], next)
        }
      },
      {
        path: '/forms',
        name: 'Forms',
        component: () => import('../views/forms/Forms.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['FORMS_VIEWLIST'], next)
        }
      },
      {
        path: '/forms/detail/:formId',
        name: 'formView',
        component: () => import('../views/form-detail/FormDetail.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['FORMS_VIEWLIST'], next)
        }
      },
      {
        path: '/forms/new',
        name: 'FormNew',
        component: () => import('../views/form-forms/FormForms.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['FORMS_EDIT'], next)
        }
      },
      {
        path: '/forms/edit/:formId',
        name: 'FormEdit',
        component: () => import('../views/form-forms/FormForms.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['FORMS_EDIT'], next)
        }
      },
      {
        path: '/settings/general',
        name: 'SettingsGeneral',
        component: () => import('../views/settings-general/SettingsGeneral.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['ORGANIZATION_SETTINGS_GENERAL'], next)
        }
      },
      {
        path: '/settings/profiles',
        name: 'Profiles',
        component: () => import('../views/settings-profiles/SettingsProfiles.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['ORGANIZATION_SETTINGS_ROLES_VIEW'], next)
        }
      },
      {
        path: '/settings/versions',
        name: 'Versions',
        component: () => import('../views/settings-versions/SettingsVersions.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['ORGANIZATION_SETTINGS_GENERAL'], next)
        }
      },
      {
        path: '/categories/patient/:id',
        name: 'EditPatientCategories',
        component: () => import('../views/form-patient-category/FormPatientCategory.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['PATIENT_CATEGORY_EDIT'], next)
        }
      },
      {
        path: '/categories/patient',
        name: 'PatientCategories',
        component: () => import('../views/patientCategories/PatientCategories.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['PATIENT_CATEGORY_VIEWLIST'], next)
        }
      },
      {
        path: '/categories/suspicions',
        name: 'SuspicionCategories',
        component: () => import('../views/suspicionCategories/SuspicionCategories.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['SUSPICTIONS_CATEGORIES_VIEWLIST'], next)
        }
      },
      {
        path: '/suspicion/categories/new',
        name: 'NewSuspicionCategory',
        component: () => import('../views/form-suspicion-category/FormSuspicionCategory.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['SUSPICTIONS_CATEGORIES_EDIT'], next)
        }
      },
      {
        path: '/categories/suspicions/:id',
        name: 'FormSuspicionCategory',
        component: () => import('../views/form-suspicion-category/FormSuspicionCategory.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['SUSPICTIONS_CATEGORIES_EDIT'], next)
        }
      },
      {
        path: '/products/:id',
        name: 'EditProducts',
        component: () => import('../views/form-products/FormProducts.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['PRODUCTS_EDIT'], next)
        }
      },
      {
        path: '/suspicions/new',
        name: 'NewSuspiction',
        component: () => import('../views/newSuspiction/NewSuspiction.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['SUSPICTIONS_CREATE'], next)
        }
      },
      {
        path: '/suspicions/:id',
        name: 'SuspictionDetail',
        component: () => import('../views/suspictionDetail/SuspictionDetail.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['SUSPICTIONS_VIEW'], next)
        }
      },
      {
        path: '/suspicions/:suspicionId/investigation/:investigationId',
        name: 'Investigation',
        component: () => import('../views/investigation/Investigation.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['INVESTIGATIONS_VIEW', 'INVESTIGATIONS_EDIT'], next)
        }
      },
      {
        path: '/categories/patient/new',
        name: 'NewPatientCategory',
        component: () => import('../views/form-patient-category/FormPatientCategory.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['PATIENT_CATEGORY_EDIT'], next)
        }
      },
      {
        path: '/forms/:id/clone',
        name: 'CloneForm',
        component: () => import('../views/clone-form/CloneForm.vue'),
        beforeEnter: (to, form, next) => {
          hasPermissionToEnter(['FORMS_CLONE'], next)
        }
      }
    ]
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import('../components/unauthorized/Unauthorized.vue')
  },
  {
    path: '/auth/:organizationCode',
    name: 'Authentication',
    component: () => import('../views/authentication/authentication.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/not-found/NotFound.vue'),
    beforeEnter: (to, from, next) => {
      hasTokenValid(next)
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
