import axios from 'axios'
import authenticationService from '@/services/authentication'
import configurationsService from '@/services/configurations'
import moment from 'moment'
import decode from 'jwt-decode'

export default {
  loginFromExternal (organizationCode, token) {
    return axios
      .post(`${configurationsService.getConfiguration('API_URL')}/auth/login/fromexternal`, {
        token,
        organization_code: organizationCode
      })
      .then((response) => {
        return response.data
      })
  },
  getMyToken () {
    return axios({
      method: 'get',
      url: `${configurationsService.getConfiguration('API_URL')}/auth/mytoken`,
      headers: {
        Authorization: `Bearer ${authenticationService.getToken()}`
      }
    })
      .then((response) => {
        return response.data
      })
  },
  getToken () {
    return localStorage.getItem('token')
  },
  getDataToken () {
    return decode(localStorage.getItem('token'))
  },
  validateReauthentication () {
    const token = localStorage.getItem('token')
    const decodedToken = decode(token)
    const expiredToken = moment(new Date(decodedToken.exp * 1000))
    const actualTime = moment()
    const minutesExpired = configurationsService.getConfiguration('TOKEN_REAUTHENTICATION_TIME') ? parseInt(configurationsService.getConfiguration('TOKEN_REAUTHENTICATION_TIME')) : 5
    if (expiredToken.diff(actualTime, 'minutes') < 0) {
      return {
        expired: true,
        date: new Date(decodedToken.exp * 1000)
      }
    }
    if (expiredToken.diff(actualTime, 'minutes') <= minutesExpired) {
      return {
        expired: true,
        date: new Date(decodedToken.exp * 1000)
      }
    }
    return {
      expired: false,
      date: new Date(decodedToken.exp * 1000)
    }
  },
  reauthenticate (password) {
    return axios({
      method: 'post',
      url: `${configurationsService.getConfiguration('API_URL')}/auth/reauthenticate`,
      data: {
        password
      },
      headers: {
        Authorization: `Bearer ${authenticationService.getToken()}`
      }
    })
      .then((response) => {
        return response.data
      })
  }
}
